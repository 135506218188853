<template>
  <nav id="server-status" class="navbar off">
    <div class="user">
      <span class="user-photo">
        <img src="../assets/user.png" />
      </span>

      <span class="user-name">
        <h2>{{ this.$root.Usuario.Nome }}</h2>
        <span id="conect-state">Offline</span>
      </span>
    </div>

    <div class="logout">
      <button @click="Logout()" class="btn-logout">
        <i class="ri-shut-down-line"></i>
        Sair        
      </button>
    </div>

  </nav>

</template>

<script>
import { signOut } from "../services/auth"
import { useConfirm } from "primevue/useconfirm";

export default {
  name: "NavMenu",
  data() {
    return {
      confirm: useConfirm()
    }
  },
  methods: {
    Logout: function () {
      this.confirm.require({
        message: 'Deseja realmente sair?',
        header: 'Sair',
        acceptLabel: 'Sim',
        rejectLabel: 'Não',
        acceptClass: 'p-button-danger',
        rejectClass: '',
        accept: async () => {
          await signOut();
          this.$root.Usuario = null;
          this.$router.push("Login");
        }
      })     
    },
  },
}
</script>

<style>
.on {
  --status-color: var(--green-500);
  --shadow-color: rgba(144, 222, 83, 0.2);
}

.off {
  --status-color: var(--red-500);
  --shadow-color: rgba(219, 67, 102, 0.3);
}

.navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 28rem;
  height: 7rem;
  padding: 0 1rem;
  position: fixed;
  top: 0;
  left: 0;
  background-color: var(--gray-600);
  z-index: 9;
}

.user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
}

.user-photo {
  width: 2.75rem;
  height: 2.75rem;
  padding: 0.125rem;
  border-radius: 100px;
  border: 1px solid var(--status-color);
  position: relative;
  filter: drop-shadow(0px 4px 16px var(--shadow-color));
}

.user-photo img {
  width: 100%;
  border-radius: 100px;
}

.user-photo:before {
  content: "";
  width: 0.875rem;
  height: 0.875rem;
  position: absolute;
  right: 0;
  bottom: -0.25rem;
  border-radius: 100px;
  outline: 2px solid var(--gray-500);
  background-color: var(--status-color);
}

.user-name h2 {
  font-size: 0.875rem;
  letter-spacing: 0.5px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0;
}

#conect-state {
  font-size: 0.75rem;
  letter-spacing: 1.5px;
  font-weight: 400;
  color: var(--status-color);
  text-transform: lowercase;
}

.btn-logout {
  background-color: transparent;
  color: var(--gray-200);
  border: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  font-size: 0.75rem;
  letter-spacing: 1.25px;
  text-transform: lowercase;
}

.btn-logout i {
  font-size: 1.5rem;
  line-height: 1;
  display: flex;
}
</style>
