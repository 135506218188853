export default {    
    get(key){
        let sessaoJson = localStorage.getItem(key);
        let valueObj = JSON.parse(sessaoJson);
        return valueObj;
    },
    set(key, value){
       let valueJson = JSON.stringify(value);
       localStorage.setItem(key, valueJson);
    }
}