<template>
  <Toast />     
  <ConfirmDialog></ConfirmDialog>
  <NavMenu v-if="this.Usuario" />
  <router-view />  
</template>

<script>
import NavMenu from "./components/NavMenu.vue"
import Storage from "./services/localStorage"
import { signOut } from "./services/auth"


export default {
  name: "App",
  data() {
    return {
      Usuario: {}
    }
  },
  components: {
    NavMenu    
  },
  async mounted() {
    
    this.Usuario = Storage.get("Usuario");

    let routeCurrenteName = window.location.pathname;
    
    if (!this.Usuario && routeCurrenteName  !== "/Login") {
       alert('Você precisa estar logado para acessar!');  
       await signOut()
       this.$router.push("Login");
    }
  }
}
</script>

<style>
:root {
  --gray-900: #111217;
  --gray-800: #16171e;
  --gray-700: #16171e;
  --gray-600: #242632;
  --gray-500: #282a37;
  --gray-400: #53555f;
  --gray-300: #6f7079;
  --gray-200: #9c9da3;
  --gray-100: #bcbdc1;
  --gray-50: #eaeaeb;

  --blue-900: #0c3c66;
  --blue-800: #104f86;
  --blue-700: #1566ad;
  --blue-600: #1a83de;
  --blue-500: #1d90f4;
  --blue-400: #4aa6f6;
  --blue-300: #68b5f8;
  --blue-200: #97ccfa;
  --blue-100: #b9ddfc;
  --blue-50: #e8f4fe;

  --red-500: #db4366;
  --green-500: #90de53;
}

:focus {
  outline: none;
  box-shadow: 0 0 0 2px var(--blue-300) !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: var(--gray-500);
}

::-webkit-scrollbar-thumb {
  background: var(--gray-300);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--gray-600);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  width: 100%;
  min-height: 100vh;
  opacity: 1;
  background: linear-gradient(167.95deg, #282A37 0%, #111217 80.55%), #282A37;
  color: var(--gray-50);
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
}

body,
input,
textarea,
button {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  padding: 0 1rem;
}

/* BUTONS */

.btn,
.btn:focus,
.btn:active {
  transition: background-color .3s, box-shadow .3s;
}

.btn-primary {
  background-color: #d2010d;
  border: none;
  border-radius: 12px;
  height: 3.5rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-size: 0.825rem;
  color: var(--blue-50);
  box-shadow: 0px 4px 24px rgba(220, 13, 9, 0.5);
}

.btn-primary:hover,
.btn-primary:active {
  background-color: #f41623 !important;
  box-shadow: 0px 4px 24px rgba(220, 13, 9, 0.5);
}

.btn-success {
  background-color: #d2010d;
  border: none;
  border-radius: 12px;
  height: 3rem;
  letter-spacing: 1.25px;
  text-transform: uppercase;
  font-size: 0.825rem;
  color: #fff;
  font-weight: 500;
  box-shadow: 0px 4px 24px #842029;
}

.btn-success:hover,
.btn-success:active {
  color: #fff;
  background-color: #f41623 !important;
  box-shadow: 0px 4px 24px #842029;
}
</style>
