var TextUtil = {
    RemoverAcentos: async function (str) {
        var acentos = "áàãâäéèêëíìîïóòõôöúùûüçÁÀÃÂÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ";
        var letras = "aaaaaeeeeiiiiooooouuuucAAAAAEEEEIIIIOOOOOUUUUC";
        var retorno = "";
        for (var i = 0; i < str.length; i++)
            if (acentos.indexOf(str[i].toString()) >= 0)
                retorno += letras[acentos.indexOf(str[i].toString())];
            else
                retorno += str[i];
        return retorno;
    },

    ToMoney: async function (value) {
        return parseFloat(("" + value).replace(',', '.')).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    },

    ToCNPJ: async function (value) {
        var cnpj = value.replace(/^(\d{2})(\d)/, "$1.$2")
    
        //Coloca ponto entre o quinto e o sexto dígitos
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3")
    
        //Coloca uma barra entre o oitavo e o nono dígitos
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2")
    
        //Coloca um hífen depois do bloco de quatro dígitos
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2")
    
        return cnpj;
    },
    
    ToCEP: async function (value) {
        var cep = value.replace(/^(\d{5})(\d)/, "$1-$2")
    
        return cep;
    },
    
    ToPhone: async function ToPhone(value) {
        var phone = value.replace(/^(\d{2})(\d{1})(\d{4})/, "($1) $2 $3-")
        return phone;
    },

    LimparString: async function (string) {
        var resultado = string.replace(/[^\w\s]/gi, '')
        return resultado;
    },
    
    RemoveEspacos: async function (string) {
        return string.replace(/ /g, '');
    },
        
    ValidarCelular: async function (celular) {
        var regex = new RegExp('^((1[1-9])|([2-9][0-9]))((3[0-9]{3}[0-9]{4})|(9[0-9]{3}[0-9]{5}))$');
        return regex.test(celular);
    },
    
    /**
     *
     * @param {string} email
     */
    ValidarEmail: async function (email) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    },
    
    /**
     * 
     * @param {string} cpf
     */
     ValidarCPF: async function (cpf) {
        cpf = cpf.replace(/[^\d]+/g, '');
        if (cpf == '') return false;
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            return false;
        add = 0;
        for (i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
            return false;
        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(10)))
            return false;
        return true;
    },
    
    /**
     * 
     * @param {string} cnpj
     */
    ValidarCNPJ: async function (cnpj) {
        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj == '') return false;
        if (cnpj.length != 14)
            return false;
        if (cnpj == "00000000000000" ||
            cnpj == "11111111111111" ||
            cnpj == "22222222222222" ||
            cnpj == "33333333333333" ||
            cnpj == "44444444444444" ||
            cnpj == "55555555555555" ||
            cnpj == "66666666666666" ||
            cnpj == "77777777777777" ||
            cnpj == "88888888888888" ||
            cnpj == "99999999999999")
            return false;
        tamanho = cnpj.length - 2
        numeros = cnpj.substring(0, tamanho);
        digitos = cnpj.substring(tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(0))
            return false;
        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado != digitos.charAt(1))
            return false;
        return true;
    }
        
}

var DateUtil = {
    FormataData: function(value){
        let dateObj = new Date(value);
        let date = dateObj.toLocaleDateString();
        let time = dateObj.toLocaleTimeString();
        let datetime = date + ' ' + time;
  
        return datetime;
    },

    ValidaDatas: async function (DataDe, DataAte) {
        var DataAtual = new Date().getDate();
    
        if (DataDe == undefined || DataDe == '' || DataAte == undefined || DataAte == '') {
            alert('As datas devem estar preenchidas!');
            return false;
        }
        else if (DataDe > DataAte || DataDe > DataAtual) {
            alert('O campo Data De não pode ser Maior que a Data Até e a Data Atual!');
            return false;
        }
        else if (DataAte < DataAte || DataAte > DataAtual) {
            alert('O campo Data Até não pode ser Menor que a Data De ou Maior que a Data Atual!');
            return false;
        }
        else {
            return true;
        }
    },

    DateToday: async function () {
        var today = new Date();
        var dd = today.getDate();
        var mm = today.getMonth();
        var yyyy = today.getFullYear();
        return new Date(yyyy, mm, dd);
    }
}

var FileUtil = {
    ToExcel: function (id) {        
        var htmltable = document.getElementById(id);
        var html = htmltable.outerHTML;
    
        window.open('data:application/vnd.ms-excel,' + encodeURIComponent(html));        
    }
}

var ObjectUtil = {
    HasClass: function ( target, className ) {
        return new RegExp('(\\s|^)' + className + '(\\s|$)').test(target.className);
    },

    RemoveClass: function (target, className) {        
        for (var i = 0; i < target.length; i++) {
            let el = target[i];
            let newClass = el.className.replace(className, '');
            el.className = newClass;
        }      
    }
}

var Modules = {
    TextUtil,
    DateUtil,
    FileUtil,
    ObjectUtil
}

export default Modules