import storage from './localStorage';
import { isSignedIn, signOut } from '../services/auth';

async function request (method, url, body) {
  const usuario = storage.get('Usuario');  
  let headers = {
    'Content-Type': 'application/json'    
  };
  
  if (usuario) {    
    if (isSignedIn()) {
      headers['Authorization'] =  `Bearer ${usuario.token.result}`;      
    }
    else{
      signOut();
      window.location.reload();
    }
  }
    
  body = JSON.stringify(body);

  const options = {        
    method,
    headers,
    body
  };  
  
  const response = await fetch(url, options);
  return await response.json();
}

export { request as default, request }