import "bootstrap/dist/css/bootstrap.css"
import "primevue/resources/themes/lara-light-indigo/theme.css";
import 'primeicons/primeicons.css';

import { createApp } from 'vue'
import App from './App.vue'
import router from './services/router'
import storage from './services/localStorage';
import utils from './services/utils';
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';
import DialogService from 'primevue/dialogservice'
import Dialog from 'primevue/dialog';
import Toast from 'primevue/toast';
import ConfirmationService from 'primevue/confirmationservice';
import InputMask from 'primevue/inputmask';
import ConfirmDialog from 'primevue/confirmdialog';
import Button from 'primevue/button';

const app = createApp(App)

//Configura appsettings
fetch("./appsettings.json")
  .then((response) => response.json())
  .then((config) => {    
    app.config.globalProperties.$appsettings = config;
    storage.set("AppSettings", config)
  });

//Adiciona utils global
app.config.globalProperties.$utils = utils;

app.use(router);
app.use(PrimeVue, { ripple: true });
app.use(ToastService);
app.use(DialogService);
app.use(ConfirmationService);

app.component('Button', Button);
app.component('ConfirmDialog', ConfirmDialog);
app.component('Dialog', Dialog);
app.component('Toast', Toast);
app.component('InputMask', InputMask);

app.mount('#app');

import "bootstrap/dist/js/bootstrap.js"