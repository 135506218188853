import decode from 'jwt-decode';
import request from './request';
import storage from './localStorage';
import { getAppSettings }  from '../services/appsettings';

let baseURL = storage.get("AppSettings")?.Api?.UrlWhats;

export async function signIn (Login, Senha) {
  try {
    if(!baseURL){
      var settings = await getAppSettings();
      baseURL = settings?.Api?.UrlWhats;
      storage.set("AppSettings", settings);
    }
  
    const result = await request('POST', `${baseURL}/Authentication`, {
      Login,
      Senha
    });
    
    
    if (result.sucesso) {
      storage.set('Usuario', result.data);      
    }
    
    return result;
  } catch (error) {
    return {
       Sucesso: false,
       Mensagem: 'Erro ao conectar no servidor'
    }
  } 
}

export async function signOut () {
  localStorage.clear();
  console.clear();
}

export function isSignedIn () {
  const usuario = storage.get('Usuario');
  
  if(!usuario)
    return false;
  
  if (!usuario.token)    
    return false;     

  try {
    const { exp: expiration } = decode(usuario.token.result);
    const isExpired = !!expiration && Date.now() > expiration * 1000;    

    if (isExpired)  
      return false; 
                    
    return true;
  } catch (_) {  
    return false;
  }
}