import { createWebHistory, createRouter } from "vue-router";
import { isSignedIn, signOut } from './auth';

let routeProduction = '/';

const routes = [
    {
      path: routeProduction,
      name: "Root",     
      beforeEnter: (to, from, next) =>
      {        
        next(`${routeProduction}Login`);
      }
    },
    {
      path: `${routeProduction}Login`,
      name: "Login",
      component: () => import("@/views/Login.vue"),
      beforeEnter: (to, from, next) =>
      {
        if (!isSignedIn())
        {       
          next();      
          return;
        }
        
        next(`${routeProduction}Home`);
      }
    },
    {
      path: `${routeProduction}Home`,
      name: "Home",
      component: () => import("@/views/Home.vue"),
      beforeEnter: (to, from, next) =>
      {
        if (isSignedIn())
        {       
          next();          
          return;
        }

        alert('Você precisa estar logado para acessar!');        
        next(`${routeProduction}Login`);
      }
    },
    {
      path: "/:pathMatch(.*)*", 
      beforeEnter: (to, from, next) => { 
        next(`${routeProduction}404`) ;
      } 
    },
    {
      path: `${routeProduction}404`,
      name: '404',
      component: () => import("@/views/404.vue"),
    }
  ];  

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });

  export default router;